.field-label-text {
  padding-right: 0;
  color: var(--spectrum-alias-label-text-color,var(--spectrum-global-color-gray-700));
}

:not(h5) > span.field-label-text {
  font-size: var(--spectrum-global-dimension-font-size-75);
}

.bold {
  font-weight: bold;
}

.small-button-text {
  font-size: var(--spectrum-global-dimension-font-size-75);
  font-weight: bold;
}

.small-text {
  font-size: var(--spectrum-global-dimension-font-size-75);
  font-weight: var(--spectrum-global-font-weight-regular,400);
}

.very-small-text {
  font-size: var(--spectrum-global-dimension-font-size-50);
  font-weight: var(--spectrum-global-font-weight-regular,400);
}

.error-text {
  margin-top: var(--spectrum-global-dimension-size-50);
  font-size: var(--spectrum-global-dimension-size-150);
  color: var(--spectrum-global-color-red-500);
}

.warning-text {
  margin-top: var(--spectrum-global-dimension-size-50);
  font-size: var(--spectrum-global-dimension-size-150);
  color: var(--spectrum-global-color-orange-600);
}

.error-text > span:last-child,
.warning-text > span:last-child {
  margin-left: 6px;
}

.yellow-text {
  color: var(--spectrum-global-color-orange-600);
}

.frame-name {
  font-family: 'Courier New', Courier, monospace;
}

.bold-text {
  font-weight: bold;
}

.autocapitalize-text {
  text-transform:capitalize;
}

.paragraph-font-size {
  font-size: var(--spectrum-global-dimension-size-150);
}

.index-link {
  padding: var(--spectrum-global-dimension-size-65);
  padding-left: 0;
  padding-right: 0;
  word-break: break-word;
}