body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Spectrum web components */
.sp-accordion {
  --spectrum-alias-ui-icon-chevron-size-100:var(--spectrum-global-dimension-static-size-125);
}
.sp-tags {
  --spectrum-alias-item-workflow-icon-gap-s: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-item-height-s: var(--spectrum-global-dimension-size-300);
  --spectrum-alias-item-text-size-s: var(--spectrum-global-dimension-font-size-75);
  --spectrum-alias-item-workflow-padding-left-s: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-item-padding-s: var(--spectrum-global-dimension-size-85);
}